<template>
    <div>
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
        >
        {{ title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li>{{ text1 }}</li>
            <li>{{ text2 }}</li>
            <li>{{ text3 }}</li>
            <li>{{ text4 }}</li>
        </ul>
        </v-alert>
    </div>
</template>

<script>

export default {
    name: 'info-user-type-velika-zaka',
    data: () => ({
        title: 'Izbrani tip kartice Velika Zaka:',
        text1: "v lasti ali službeni uporabi fizične osebe s stalnim prebivališčem v Občini Bled, ki za njegovo uporabo plačuje predpisano boniteto, kar izkaže v postopku izdaje dovolilnice",
        text2: "je v uporabi fizične osebe s stalnim prebivališčem v Občini Bled na podlagi pogodbe o lizingu oziroma pogodbe o poslovnem najemu",
        text3: "za prehod območja med Pristavo in Železniško postajo Bled - Jezero v času med 15.6 in 15.9",
        text4: "kartica Velika Zaka ne omogoča koriščenje parkirišč"
    })
}

</script>